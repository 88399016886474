.actions {
  display: flex;
  align-items: center;

  &Item {
    display: flex;
    margin-right: 20px;

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.actionsButton {
  background-color: transparent;
  border: none;
  outline: none;
}

.search {
  svg {
    circle,
    path {
      stroke: var(--header-text-color);
      transition: var(--transition);
    }
  }

  &:hover {
    svg {
      circle,
      path {
        stroke: var(--primary);
      }
    }
  }
}

.siteNavigationButton {
  svg {
    color: var(--black) !important;
  }
}
